import { navigate } from '@reach/router';
import { AxiosError } from 'axios';
import Link from 'gatsby-link';
import React, { FC } from 'react';
import { XOctagon } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { PasswordInput } from '../components/PasswordInput';
import { H4 } from '../components/Typography';
import { login as loginAction } from '../lib/actions/me';
import { Paths } from '../paths';
import { getBodyMessage } from '../utils/function-utils';
import { HelpText } from './commonStyles';

const Container = styled.div`
  max-width: 820px;
  padding: 60px 50px;
`;

export const LoginForm: FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const dispatch = useDispatch();

  async function login(email: string, password: string) {
    try {
      await dispatch(loginAction(email, password));
      navigate(Paths.Home);
    } catch (err) {
      toast.error(getBodyMessage(err as AxiosError), {
        icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
      });
    }
  }

  const onSubmit = async (values: { email: string; password: string }) => {
    await login(values.email, values.password);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">Login to your account</H4>

        <Input
          label="Email"
          className="mb-4"
          error={!!errors.email}
          helpText={
            errors.email && (
              <HelpText>{errors.email?.type === 'required' ? 'Email is required' : 'Email is invalid'}</HelpText>
            )
          }
          inputStyle={{ color: '#333' }}
          {...register('email', {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />

        <PasswordInput label="Password" className="mb-4" inputStyle={{ color: '#333' }} {...register('password')} />

        <div className="flex justify-end mt-4">
          <div className="flex flex-col mr-auto">
            <Link to={Paths.SignUp}>Don&apos;t have an account? Sign Up</Link>
            <Link to={Paths.ForgotPassword}>Forgot Password?</Link>
            <Link to={Paths.RequestVerification}>Resend verification email</Link>
          </div>

          <Button type="submit" disabled={isSubmitting}>
            Log in
          </Button>
        </div>
      </Container>
    </form>
  );
};
